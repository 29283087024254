import { ContentBlock, Typography, Button, Header } from '@/components';
import { InternetConnectionContext } from '@/GlobalProvider/GlobalProvider';
import { useIframeMessageHandler } from '@/utils';
import * as React from 'react';
import { useContext, useLayoutEffect, FC } from 'react';
import { createUseStyles } from 'react-jss';

const bpsToMbps = (bps) => {
  return Math.round((bps / 1000000) * 10) / 10;
};

export const useStyles = createUseStyles({
  header: {
    textAlign: 'center',
    marginBottom: 40,
    '@media (max-width: 576px)': {
      marginBottom: 30
    }
  },
  title: {
    marginBottom: 15
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 25
  },
  itemValue: {
    fontWeight: 400
  },
  refreshButton: {
    marginTop: 20
  },
  loadingText: {
    paddingBottom: 25
  }
});

export const InternetConnection: FC = () => {
  const styles = useStyles();
  const { refresh, measurements, IP } = useContext(InternetConnectionContext);

  useIframeMessageHandler();

  useLayoutEffect(() => {
    refresh();
  }, []);

  return (
    <>
      <Header />
      <ContentBlock hasBackButton>
        <div className={styles.header}>
          <Typography className={styles.title} component="h2">
            Internet Connection
          </Typography>
        </div>
        <div className={styles.content}>
          {measurements ? (
            <div>
              <Typography component="h4">
                Upload:{' '}
                <span className={styles.itemValue}>
                  {bpsToMbps(measurements.upload)} Mbps
                </span>
              </Typography>
              <Typography component="h4">
                Download:{' '}
                <span className={styles.itemValue}>
                  {bpsToMbps(measurements.download)} Mbps
                </span>
              </Typography>
              <Typography component="h4">
                Jitter:{' '}
                <span className={styles.itemValue}>
                  {Math.round(measurements.jitter * 100) / 100} ms
                </span>
              </Typography>
              <Typography component="h4">
                IP: <span className={styles.itemValue}>{IP}</span>
              </Typography>
              <Button
                text="Refresh"
                className={styles.refreshButton}
                buttonType="secondary"
                onClick={refresh}
              />
            </div>
          ) : (
            <Typography component="h6" className={styles.loadingText}>
              Evaluating network quality, please wait...
            </Typography>
          )}
        </div>
      </ContentBlock>
    </>
  );
};
